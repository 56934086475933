import React, { useState } from 'react';
import { useEffect } from 'react';

function ReadingTime(props) {

    const { content } = props;

    const [time, setTime] = useState(0);

    useEffect(() => {        
        const wpm = 200;
        const words = content?.split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        setTime(time);
    }, [content]);

    return (
        <> 
            {content ? (
                <span className="division">                    
                    <span className="dot">&#9679;</span>
                    <span><span className="time">{time} min read</span></span>
                </span>
            ) : (
                <span className="time">{time} min read</span>               
            ) }
        </>
    );
}

export default ReadingTime;