import Link from "next/link";
import SidebarPost from "./sidebar-blog-post";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import SearchForm from "./SearchForm";
import SidebarPWA from "../PWA/SidebarPWA";

export default function BlogSidebar({
  latestPosts,
  popularPosts,
  pageType = null,
}) {
  return (
    <aside className="aside-bar">
      
      <SearchForm/>
      <hr />

      <SidebarPWA/>
      <hr />
      
      {latestPosts.length && (
        <SidebarPost posts={latestPosts} title="Recent News" />
      )}
      <hr />

      <div className="recent-card-sec">
        <h3 className="r-c-title">Follow Us on Social Media</h3>
        <ul className="social">

          <li>
            <Link href="https://www.facebook.com/Gossipment-104612085189662">
              <a target="_blank" rel="nofollow noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </Link>
          </li>
          
        </ul>
      </div>
      <hr />

      {popularPosts.length && (
        <SidebarPost posts={popularPosts} title="Popular News" />
      )}
    </aside>
  );
}