import Link from "next/dist/client/link";
import CelebrityImage from "../celebrity-image";

function BlogAuthor({ authorData }) {
  return (
    <section className="single-author-sec mt-3">
      <div className="author-img-card">
        <CelebrityImage
          firstName={authorData.name}
          coverImage={authorData.avatar}
        />
      </div>
      <div className="author-content-sec">
        <div className="single-author-sec-title-wrapper">
          <h2 className="author-title">
            {<Link href={authorData.uri}>{authorData.name}</Link>}
          </h2>
          <div className="social-sec-icon">
            {/* <ul className="w-100">
              <li>
                <Link href="">
                  <a>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </Link>
              </li>
              <li>
                {" "}
                <Link href="">
                  <a>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </Link>
              </li>
              <li>
                <Link href="">
                  <a>
                    <i className="fab fa-twitter"></i>
                  </a>
                </Link>
              </li>
              <li>
                <Link href="">
                  <a>
                    <i className="fab fa-instagram"></i>
                  </a>
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
        {authorData.description}
      </div>
    </section>
  );
}

export default BlogAuthor;
