import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { RWebShare } from "react-web-share";

function WebShare({ uri, title }) {
  const siteURL = process.env.NEXT_PUBLIC_SITE_URL;
  return (
    <div className="socialShare mb-5 mt-5">
      <div className="sec-title-wrapper">
        <h2 className="sec-title fw-bolder text-uppercase">
          Share on Social Media
        </h2>
      </div>

      <div className="sec-cont-wrapper">
        <Link
          href={`https://www.facebook.com/sharer/sharer.php?u=${siteURL}${uri}`}
        >
          <a target="_blank" rel="noopener noreferrer nofollow">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </Link>
        <Link href={`https://twitter.com/intent/tweet?url=${siteURL}${uri}`}>
          <a target="_blank" rel="noopener noreferrer nofollow">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        </Link>

        <RWebShare
          data={{
            url: uri,
            title: title,
          }}
          onClick={() => console.log("Shared Successfully!")}
        >
          <a>
            {" "}
            <FontAwesomeIcon icon={faShareAlt} />
          </a>
        </RWebShare>
      </div>
    </div>
  );
}

export default WebShare;
