import Link from 'next/link'

export default function Categories({ categories }) {

  const totalcat = categories.edges.length;

  return (    
    <>
      <span className="division">
        <strong>
          {totalcat > 0 ? (          
            categories.edges.map((category, index) => (
              <Link href={category.node.uri} key={index}>
                <a key={category.node.name}>{index == 0 ? '' : ' , '} {category.node.name}</a>
              </Link>          
            ))
          ) : (
            <a>{categories.edges.node.name} </a>
          )}
        </strong>
      </span>
    </>
  )
}