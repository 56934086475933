export default function PostBody({ content }) {
  const uploadsDirectory = `${process.env.NEXT_PUBLIC_WP_URL}/wp-content/uploads`;
  const pattern = new RegExp(uploadsDirectory, 'g');
  content = content.replace(pattern, `${process.env.NEXT_PUBLIC_SITE_URL}/_next/image?w=750&q=75&url=${uploadsDirectory}`);





  return (
    <section id="singlePageContent" className="content-sec" dangerouslySetInnerHTML={{ __html: content }} />
  )

}
