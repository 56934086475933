import Categories from './categories';
import Date from './date';
import PostTitle from './post-title';
import ReadingTime from './ReadingTime';
import ViewsCount from './ViewsCount';

export default function PostHeader({
  title,
  date,
  categories,
  content,
}) {
  return (
    <header className="single-page-title-card">
      <PostTitle>{title}</PostTitle>
      <article className="title-dsc">
        <p>
          <Categories categories={categories} />
          <ReadingTime content={content} />
          <span className="division">
            <span className="dot">&#9679;</span>
            <span className=""><Date dateString={date} /> </span>
          </span>
          {/* <ViewsCount/> */}
        </p>
      </article>
    </header>
  )
}