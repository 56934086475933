import Link from "next/link";
import CoverImage from "../cover-image";
import Date from "../date";

export default function CommonBlogPreview({
  title,
  content = null,
  categories,
  coverImage,
  date,
  slug,
}): React.ReactElement {
  const firstCat = categories.edges[0].node;

  return (
    <Link href={`/${slug}`}>
      <a className="c-card-tow">
        {coverImage && (
          <CoverImage title={title} coverImage={coverImage}/>
        )}

        <div className="content-wrapper">
          <div className="c-card-tow-title-wrapper mb-3">
            <div className="news-cat-sec">
              {
                <span className="">
                  <Link href={`${firstCat.uri}`}>
                    <a> {firstCat.name} </a>
                  </Link>
                </span>
              }
            </div>
            <p className="">
              <span>
                <Date dateString={date} />
              </span>
              {/* <ReadingTime content={content} /> */}
            </p>
          </div>
          <article>
            <h4
              className="content-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </article>
        </div>
      </a>
    </Link>
  );
}
