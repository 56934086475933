import Link from "next/link";
import Date from "./date";
import CoverImage from "./cover-image";

export default function SidebarPostPreview({
  title,
  categories,
  coverImage,
  date,
  slug,
}) {
  return (
    <Link href={`/${slug}`}>
      <a className="c-recent-card">
        <div className="c-recent-card-img-wrapper">
          {coverImage && <CoverImage title={title} coverImage={coverImage} />}
        </div>
        <div className="c-card-content">
          <h4
            className="c-c-c-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h4>
          <p className="c-dsc">
            <span>
              <Date dateString={date} />
            </span>
          </p>
        </div>
      </a>
    </Link>
  );
}
