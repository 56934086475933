import InstallPWAButton from "./InstallPWAbtn";

function SidebarPWA(props) {
  return (
    <div className="recent-card-sec sidebar-pwa">
      <h3 className="r-c-title">Install Our App</h3>
      <p style={{fontSize: "calc(12px + 0.5vmin)"}}>Download our Gossipment PWA to get the latest updates and news about your favorite celebrity!</p>
      <InstallPWAButton/>
    </div>
  );
}

export default SidebarPWA;
