import Link from 'next/link'

export default function Tags({ tags }) {

  return (
    <div className="card-chip-left">
      Tag:
      {tags.edges.map((tag, index) => (
        <Link key={index} href={tag.node.uri}>
          <a className="chip-card">
            {tag.node.name}
          </a>
        </Link>
      ))}
    </div>
  )
}
