import SidebarPreview from "../sidebar-post-preview";

export default function SidebarPost({ posts, title }) {
  return (
    <div className="recent-card-sec">
      <h3 className="r-c-title">{title}</h3>
      {posts.map(({ node }) => (
        <SidebarPreview
          key={node.slug}
          title={node.title}
          categories={node.categories}
          coverImage={node.featuredImage}
          date={node.date}
          author={node.author}
          slug={node.slug}
          excerpt={node.excerpt}
        />
      ))}
    </div>
  );
}
