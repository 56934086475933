import { InferGetStaticPropsType } from "next";
import ErrorPage from "next/error";
import Head from "next/head";
import { useRouter } from "next/router";
import BlogAuthor from "../components/Author/BlogAuthor";
import Container from "../components/container";
import Header from "../components/header/header";
import Layout from "../components/layout";
import RelatedBlog from "../components/Layout/RelatedBlogPost";
import PostBody from "../components/post-body";
import PostHeader from "../components/post-header";
import PostTitle from "../components/post-title";
import SEOMeta from "../components/SEOMeta";
import BlogSidebar from "../components/sidebar/BlogSidebar";
import Tags from "../components/tags";
import WebShare from "../components/WebShare";
import {
  getAllPostsWithSlug,
  getLatestBlogs,
  getPopularBlogs,
  getPostAndMorePosts,
  resolveAll,
} from "../lib/api";

export default function Post({
  post,
  posts,
  sidebarLatestPosts,
  sidebarPopularPosts,
  slug,
}: InferGetStaticPropsType<typeof getStaticProps>) {

  if (!post) {
    
    if (typeof window != "undefined") {
      // Send revalidation request
      fetch(`/api/revalidate-slug?slug=${slug}`).then((res) => {
        return <ErrorPage statusCode={404} />;
      });
    } else {
      return <ErrorPage statusCode={404} />;
    }
  }

  const router = useRouter();
  const morePosts = posts?.edges;
  if (!router.isFallback && !post?.slug) {
    return <ErrorPage statusCode={404} />;
  }

  const latestPosts = sidebarLatestPosts.edges;
  const popularPosts = sidebarPopularPosts.edges;

  return (
    <Layout postType="post" blogBanner={post.featuredImage} slug={post.slug}>
      <Head>
        <title>{post.seo.title}</title>
        <SEOMeta seoData={post.seo} />
      </Head>

      <Container>
        <Header />
        {router.isFallback ? (
          <PostTitle>Loading…</PostTitle>
        ) : (
          <>
          
            <div className="row gap-5">
              <div className="col-lg-12">
                <PostHeader
                  title={post.title}
                  content={post.content}
                  date={post.date}
                  categories={post.categories}
                />
              </div>
              <div className="col-lg-8">
                <article className="main-content-wrapper">
                  <PostBody content={post.content} />

                  {post.tags.edges.length > 0 && (
                    <section className="tag-sec">
                      <Tags tags={post.tags} />
                    </section>
                  )}

                  {/* Web Share */}
                  <WebShare uri={post.uri} title={post.title}/>
                  
                  {/* Author section */}
                  <BlogAuthor authorData={post.author.node} />                    

                  {morePosts.length && <RelatedBlog posts={morePosts} />}
                </article>
              </div>
              <div className="col">
                <BlogSidebar
                  latestPosts={latestPosts}
                  popularPosts={popularPosts}
                />
              </div>
            </div>

            {/* <SectionSeparator /> */}
          </>
        )}
      </Container>
    </Layout>
  );
}

export async function getStaticProps({ params, preview = false, previewData }) {
  if (!params.slug) return;

  const limit = 5;

  try {
    const [data, sidebarLatestPosts, sidebarPopularPosts, allPosts] =
      await resolveAll([
        getPostAndMorePosts(params.slug, preview, previewData),
        getLatestBlogs(limit, preview),
        getPopularBlogs(limit, preview),
        getAllPostsWithSlug(),
      ]);

    return {
      props: {
        preview,
        post: data?.post ?? "",
        posts: data?.posts ?? "",
        sidebarLatestPosts,
        sidebarPopularPosts,
        allPosts,
        slug: params.slug,
      },
      revalidate: 60 * 60 * 24,
    };
  } catch (error) {
    return {
      props: {
        slug: params.slug,
      },
    };
  }
}

export async function getStaticPaths() {
  const allPosts = await getAllPostsWithSlug();

  return {
    paths: allPosts.edges.map(({ node }) => `/${node.slug}`) || [],
    fallback: true,
  };
}
