import CommonBlogPreview from "./common-blog-preview";

export default function RelatedBlog({ posts }) {
  return (
    <section className="trending-post-sec mt-5">
      <div className="com-md-12">
        <div className="sec-title-wrapper mb-5">
          <h2 className="sec-title fw-bolder text-uppercase">Related Blog</h2>
        </div>
      </div>
      <div className="post-grid-wrapper">
        {posts.map(({ node }) => (
          <CommonBlogPreview
            key={node.slug}
            title={node.title}
            categories={node.categories}
            coverImage={node.featuredImage}
            date={node.date}
            slug={node.slug}
            excerpt={node.excerpt}
          />
        ))}
      </div>
    </section>
  );
}
